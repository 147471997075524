import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import { Link } from "../util/router";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  contentBox: {
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      justifyContent: "center",
  },
  contentButton: {
    maxWidth: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30px",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "800px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
      
    },
  },
}));

function OurStorySection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Lorem Ipsum",
      body: "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
      image: "https://uploads.divjoy.com/undraw-fish_bowl_uu88.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Nunc nulla mauris, laoreet vel cursus lacinia, consectetur sit amet tellus.",
      image: "https://uploads.divjoy.com/undraw-directions_x53j.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lobortis, metus et mattis ullamcorper",
      image: "https://uploads.divjoy.com/undraw-stability_ball_b4ia.svg",
    },
    {
      title: "Lorem Ipsum",
      body: "Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo",
      image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        
        
 
                
                <Box p={6} className={classes.contentBox}>
                  
                  <Typography variant="h3" gutterBottom={true}>
                  {props.title}
                  </Typography>
                  <Typography variant="subtitle1">
                  We knew that we wanted to measure the emotional connection people have with each other, in order to help people build deeper relationships with themselves and others. It was that mission that led us to the reams of science on this topic, and helped us build the Of One Mind mobile app.
                  </Typography>
<br></br>
                  <Typography variant="subtitle1">
                  What we discovered in all this research was that when people are emotionally connected to an experience, it actually changes their sympathetic and parasympathetic nervous systems, which in turn changes the way their heart beats.
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle1">
                  Once we figured out what to listen for, we built the wildly successful mobile app that uses an Apple Watch to listen for these changes.
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle1">
                  It wasn't long after we started our quiet beta of the app that a famous UX researcher came to us asking if it could be used to measure the emotional response of website visitors.
                  </Typography>

                  <br></br>
                  <Typography variant="subtitle1">
                  Today, we have leaders in the UX world, just like you, using Of One Mind to measure what truly matters when a visitor shows up on a website.  Clicks and opens and purchasing only happens when a user has connected to the story being told.  We measure the emotional impact of the story, so you can use all your other tools to make the site convert. 
                  </Typography>

                  



                </Box>

        
      </Container>
    </Section>
  );
}

export default OurStorySection;
