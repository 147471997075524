import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="primary"
        size="large"
        bgImage="./images/o1m_ux_header.jpg"
        bgImageOpacity={0.5}
        title="Real-time Neuroscience for UX Testing"
        subtitle="Measure the engagement and psychological safety of 
        people as they navigate your site.  Identify trends, trouble points, and moments of
        delight as they happen.  Export the data to combine with eye-tracking, heatmaps,
        and your own internal tools. "
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
      <ContentSectionImageRight
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Measure what Matters"
        bodyText="Connect Of One Mind UX to your website so you can measure what neurologic brain response second-by-second.  Knowing what people love is more powerful than knowing what people look at."
        subtitle="All the features you need to move faster"
        mainImage="/images/home_01.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
      <ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Simple to Deploy"
        bodyText="This works just like your website heat mapping solution.  Drop a small javascript reference into the html head of every page, and Of One Mind will take care of the rest.  Showing the invite to users, and walking them thru the process.  Once they are collecting data, you will see their brain respond in real-time."
        subtitle="All the features you need to move faster"
        mainImage="/images/home_02.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
      <ContentSectionImageRight
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Proven Neuroscience"
        bodyText="Based on the leading research of love, trust, and storytelling, Of One Mind uses the Apple Watches that 35% of people already wear to measure how people's brains engage with your website.  No more cameras, or funny senors to wear on your head, you can deploy modern neuroscience for UX testing today."
        subtitle="All the features you need to move faster"
        mainImage="/images/email_header_usage_product_shot.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
      <FeaturesSection
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <ClientsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Used by people at companies like..."
        subtitle=""
      />
      <TestimonialsSection
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
    </>
  );
}

export default IndexPage;
