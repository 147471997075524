import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";
import OurStorySection from "./../components/OurStorySection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="/images/o1m_ux_header.jpg"
        bgImageOpacity={0.5}
        title="Measure the stuff that truly matters"
        subtitle="We believe that teams can only move the needle if they know where the needle is pointing. Of One Mind allows your team to get to the heart of what motivates humans -- the emotional engagement they have with an experience."
      />

<OurStorySection
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Our Story"
        bodyText="We knew that we wanted to measure the emotional connection people have with each other, in order to help people build deeper relationships with themselves and others. It was that mission that led us to the reams of science on this topic, and helped us build the Of One Mind mobile app."
        subtitle="All the features you need to move faster"
        mainImage="/images/home_01.jpg"
        buttonText=""
        buttonColor="secondary"
        buttonPath="/pricing"
      />
      

     
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Try it for FREE"
        buttonColor="secondary"
        buttonPath="/pricing"
      />
    </>
  );
}

export default AboutPage;
