import React, { useState , useEffect} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeaderBack from "./SectionHeaderBack";
import DashboardItems from "./DashboardItems";
import DashboardCardItems from "./DashboardCardItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from '@material-ui/icons/Timeline';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import DeleteIcon from '@material-ui/icons/Delete';
import { updateSite, useSitesByAccount, useSite, updateResult, useResultsBySite, useResult, useResultDetailsByPcodeArrayType} from "../util/db";
import '../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, DecorativeAxis, LabelSeries, Hint} from 'react-vis';
import Iframe from 'react-iframe'

const moment = require('moment');





const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  averageline: {
      stroke: 'blue',
      strokeWidth: '7px',
  },
  myaverageline: {
    backgroundColor: 'rgb(148, 206, 161, 0.4)',
    width: '100%',
    position: 'absolute',
    fontSize: '0.65em',
  },
  mybenchmarkline: {
    backgroundColor: 'rgb(233, 255, 98, 0.4)',
    width: '100%',
    position: 'absolute',
    fontSize: '0.65em',
  },
  button: {
    margin: theme.spacing(1),
  },


}));

const getEngagement = (array) => {
  let arr = Array()
  array.forEach(element => {
    arr.push({x: element['ts'], y: element['e']})
  });
  return arr
}

const getSafety = (array) => {
  let arr = Array()
  array.forEach(element => {
    arr.push({x: element['ts'], y: element['s']})
  });
  return arr
}

function ReportSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const pid = props.pid;
  const pcode = props.pcode;
  const docid = props.docid;
  const pidgroup = props.pidgroup;
  const group = props.group;

 
  const [dataSeries, setDataSeries] = useState();



  //get site info
  const { data: site, status: sitesStatus, error: sitesError } = useSite(props.siteid);
  const { data: individual, status: individualStatus, error: individualError } = useResult(props.docid);

  //get a single pid report
 
  const { data: resultData, status: resultDataStatus, error: resultDataError } = useResultDetailsByPcodeArrayType("score", props.siteid, pcode );
  const [firstTimeStamp, setFirstTimeStamp] = useState(0);
  const [benchmarkHintLocation, setBenchmarkHintLocation] = useState(0);
  const [averageHintLocation, setAverageHintLocation] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  
  
  useEffect(() => {
    console.log(individual)

    if (!!individual) {
      setStartDate(moment(individual['started_at'].toDate()).format("MMM D, YYYY hh:mm:ss A"))
      setEndDate(moment(individual['ended_at'].toDate()).format("MMM D, YYYY hh:mm:ss A"))
    }
  }, [individual]);

  useEffect(() => {
    console.log("useEffect on resultData");
    console.log(`resultData => ${JSON.stringify(resultData)}`)

    if (resultData && resultData.length > 0) {
      const item = resultData[0]
      console.log(`item => ${JSON.stringify(item)}`)
      console.log(`item ts => ${item['ts']}`)
      //setFirstTimeStamp(item['ts'])
      //setBenchmarkHintLocation({x: 0, y: 33})
      //setAverageHintLocation({x: 0, y: 65})


    }


  }, [resultData]);


  useEffect(() => {
    const bTop = 695 - (site['benchmark_score'] * 6.2)
    const aTop = 695 - (site['avg_score'] * 6.2)
    setBenchmarkHintLocation(bTop)
    setAverageHintLocation(aTop)

  }, [site]);

  useEffect(() => {

  });
  


  //useResultDetailsByPidType(type, sid, pid, pcode)

  //DO NOT NEED
  //get ux_results by site id
  const { data: results, status: resultsStatus, error: resultsError } = useResultsBySite(props.siteid);

  const [selectionModel, setSelectionModel] = useState([]);

  const selectionReady = selectionModel && selectionModel.length > 0;
  

  const showselect = (params) => {
    console.log(results)
  }

  const marginObj = { left: 40, right: 10, top: 10, bottom: 80}

  const myLabels = [
    {x: 5, y: 65, label: 'Average', style: {fontSize: 10}},
    {x: 5, y: 33, label: 'Benchmark', style: {fontSize: 10}}
  ]


//moment(v).format("hh:mm:ss")
//<XAxis top={0} hideLine tickValues={[0, 1, 3, 4, 5]} title="X"/>
//getX={d => d['ts']} getY={d => d['e']}
//<YAxis title={'Engagement'} position={'middle'} orientation={'left'}/>
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>




      {site && 
      <SectionHeaderBack
          title={`${site.name} - ${pcode}`}
          subtitle={ individual ? `${startDate} - ${endDate}` : `Loading...`}
          size={4}
          textAlign="left"
        />
      }



{results && resultData && 
    <>
   


    <Box display="flex" 
        alignItems="center"
        bgcolor="#fff"
        justifyContent="center">


{ resultData &&
        <XYPlot height={700} width={1000} yDomain={[0, 100]} margin={marginObj} style={{position: 'absolute'}} animation={true}>
          <VerticalGridLines />
          <HorizontalGridLines />

          
          <XAxis tickFormat={(v,i,s,t) => moment.unix(v).format("hh:mm:ss")} tickLabelAngle={-70} />
          <YAxis position={'middle'} orientation={'left'}/>
          <LineSeries data={getEngagement(resultData)} curve={'curveMonotoneX'} />

          

        </XYPlot>

}

{ site && 
<>
<div id="myaverage" className={classes.myaverageline} style={{top: averageHintLocation}}>Average: {site['avg_score']}</div>
<div id="mybenchmark" className={classes.mybenchmarkline} style={{top: benchmarkHintLocation}}>Benchmark: {site['benchmark_score']}</div>
</>
}

</Box>
<Box display="flex" 
        alignItems="center"
        justifyContent="end">

        <Button
        variant="contained"
        color={ individual.is_benchmark ? 'primary' : 'secondary'}
        className={classes.button}
        startIcon={<StarIcon />}
        onClick={() => {
          updateResult(props.docid, individual.is_benchmark == true ? {is_benchmark: false} : {is_benchmark: true})
        }}
      >
        { individual.is_benchmark ? (
          <>Remove from Benchmark</>
        ) : (
          <>Add to Benchmark</>
        )}
        
        
      </Button>

      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<CloudDownloadIcon />}
      >
        Download Data
      </Button>

        </Box>



   
</>
}
      </Container>
    </Section>
  );
}

export default ReportSection;
