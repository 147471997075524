import { useRef, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from "./firebase";

const auth = getAuth(firebaseApp);

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = "GET", data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth);
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

export function getEntitlements(user) {
  //auth.user.planIsActive && (auth.user.planId === "basic" || auth.user.planId === "pro");
  let entitlements = {
    sites: 1,
    can_benchmark: false,
    max_sessions: 500,
    event_api: false,
    user_attributes: false,
    download_data: false,
    remove_branding: false,
  }
  
  if (user.planIsActive) {
    if (user.planId === "basic") {
      entitlements = {
        sites: 5,
        can_benchmark: true,
        max_sessions: 1000,
        event_api: true,
        user_attributes: false,
        download_data: false,
        remove_branding: false,
      }
    } else if (user.planId === "pro") {
      entitlements = {
        sites: 10,
        can_benchmark: true,
        max_sessions: 2000,
        event_api: true,
        user_attributes: true,
        download_data: true,
        remove_branding: true,
      }
    }

  }


  return entitlements;
}