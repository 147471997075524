import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useSite, updateSite, createSite } from "../util/db";
import { useScrapper } from 'react-tiny-link'
import { Card, CardHeader, CardMedia, CardContent, CardActions, CardActionArea } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GearIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "./../util/router";
import Typography from "@material-ui/core/Typography";
import EditSiteModal from "./EditSiteModal";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { default as metaFetcher } from 'meta-fetcher';

const useStyles = makeStyles((theme) => ({
    content: {
      paddingBottom: 24,
    },
  }));
  
  function SiteCard(props) {
    const classes = useStyles();
  
    const auth = useAuth();

    const site = props.site
    const index = props.index

    const [updatingSiteId, setUpdatingSiteId] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteSiteId, setDeleteSiteId] = useState(null);


    const handleSettingsClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteClose = () => {
      setDeleteSiteId(null);
    };

    const handleConfirmDelete = () => {
      console.log(`CONFIRMED do delete of site: ${deleteSiteId}`);
      //TODO:  do the delete
      updateSite(deleteSiteId, {active: false});
      setDeleteSiteId(null);
    };
    

    const doEdit = (siteid) => {
      setUpdatingSiteId(siteid);
      setAnchorEl(null);
    };


    const [scrapresult, loading, error] = useScrapper({
        url: props.site.url,
        proxyUrl: 'https://proxy.cors.sh/',
      })

      const mfResult = metaFetcher(props.site.url);

      useEffect(() => {
        console.log("metafetch: " + mfResult);
      }, [mfResult]);


      



      //console.log(scrapresult);

    return (
            <>
            <Grid item={true} xs={12} sm={6} key={site.id}>

            <Card key={site.id}>

            <CardHeader
            avatar={
                <Avatar className={site.featured} src="/images/o1m_logo.svg" >
                {` `}
                </Avatar>
            }
            action={
              <div>
                <IconButton aria-label="settings" onClick={handleSettingsClick}>
                <GearIcon />
                </IconButton>
                <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => doEdit(site.id)}>Edit</MenuItem>
                <MenuItem onClick={() => setDeleteSiteId(site.id)}>Delete</MenuItem>
              </Menu>
              </div>
            }
            title={site.name}
            subheader={site.url}
            />
            <CardActionArea component={Link} to={`/site/${site.id}`}>
            <CardMedia
                    component="img"
                    height="194"
                    image={!!scrapresult ? scrapresult.image[0] : "https://via.placeholder.com/640x480"}
                    alt={site.name}
                />



            <CardContent>
                   
                    <Typography variant="h5" component="h2">
                    {!!scrapresult ? scrapresult.title : site.name}
                    </Typography>
                    
                    <Typography variant="body2" component="p">
                    {!!scrapresult ? scrapresult.description : ""}
                    
                    </Typography>
                </CardContent>
                </CardActionArea>
                <CardActions>
                    
                <Button variant="contained" component={Link} to={`/site/${site.id}`}
                                color="secondary" size="small">Open</Button>
                
                <Link to={`/site/${site.id}`}>
                </Link>
                </CardActions>
            </Card>

            </Grid>


            {updatingSiteId && (
                <EditSiteModal
                id={updatingSiteId}
                onDone={() => setUpdatingSiteId(null)}
                />
            )}

              {deleteSiteId && (
                <div>
                  <Dialog
                    open={true}
                    onClose={handleDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Delete this site?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        This action will delete the site and ALL data which has been collected.  Are you sure you want to continue?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteClose} color="primary">
                        No, Cancel
                      </Button>
                      <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Yes, Delete
                      </Button>
                    </DialogActions>
                  </Dialog>

                </div>
              )}



            </>
    );



  }

  export default SiteCard;