import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeaderBack from "./SectionHeaderBack";
import DashboardItems from "./DashboardItems";
import DashboardCardItems from "./DashboardCardItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from '@material-ui/icons/Timeline';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import { updateResult, useSitesByAccount, useSite, deleteSite, useResultsBySite } from "../util/db";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function SiteSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const renderDetailsButton = (params) => {
    return (
      <>
            <IconButton
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                    const reportPids = new Array()
                    reportPids.push(params.row.participant_id)

                    const reportPCodes = new Array()
                    reportPCodes.push(params.row.participant_code)

                    router.push( {pathname: `/report/${params.row.site_id}`, state: { pid: reportPids, pcode: reportPCodes, docid: params.row.id, group: false, pidgroup: []  }});
                }}
            >
                <TimelineIcon />
            </IconButton>
  
            <IconButton
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
                updateResult(params.row.id, params.row.is_benchmark == true ? {is_benchmark: false} : {is_benchmark: true})
            }}
        >
          { params.row.is_benchmark ?
            <StarIcon color="secondary" />
            :
            <StarOutlineIcon />
          }
        </IconButton>
  
        <IconButton
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                    console.log(params.row.id)
                    handleClickOpen(params.row.id);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    )
  }
  
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const handleClickOpen = id => {
    setDeleteItem(id)
    setOpenAlert(true);
  };

  const handleClose = () => {
    setDeleteItem(null)
    setOpenAlert(false);
  };

  const handleDelete = () => {
    //do delete
    updateResult(deleteItem, {deleted: true} )
    setDeleteItem(null)
    setOpenAlert(false);
  };
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 100, hide: true},
    {
      field: 'participant_code',
      headerName: 'Person',
      width: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'engagement',
      headerName: 'Engagement',
      type: 'number',
      width: 175,
      flex: 1,
      editable: false,
    },
   /* {
      field: 'safety',
      headerName: 'Safety',
      type: 'number',
      width: 175,
      editable: false,
    },*/
   
    {
      field: 'started_at',
      headerName: 'Started At',
      width: 200,
      flex: 1.5,
      editable: false,
      valueFormatter: params => 
         !!params?.value ? moment(params?.value.toDate()).format("MMM D, YYYY hh:mm:ss A") : "--",
    },
    {
      field: 'ended_at',
      headerName: 'Ended At',
      width: 200,
      flex: 1.5,
      editable: false,
      valueFormatter: params => 
      !!params?.value ? moment(params?.value.toDate()).format("MMM D, YYYY hh:mm:ss A") : "--",
    },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
  },
  ];
  

  const {
    data: site,
    status: sitesStatus,
    error: sitesError,
  } = useSite(props.siteid);


  const {
    data: results,
    status: resultsStatus,
    error: resultsError,
  } = useResultsBySite(props.siteid);

  const [selectionModel, setSelectionModel] = useState([]);

  const selectionReady = selectionModel && selectionModel.length > 0;

  const showselect = (params) => {
    console.log(results)
    //navigate(`/report/${site.id}`,{state:{pids: selectionModel, name: 'sabaoon'}});

    //TODO: limit to under 10 items
    let pidObjectArray = Array()
    let pidArray = Array()
    let pCodeArray = new Array()

    selectionModel.forEach(selection => {

      results.forEach(element => {
        if (element['id'] === selection) {
          pidObjectArray.push({id: element['id'], participant_id: element['participant_id'], participant_code: element['participant_code']})
          pidArray.push(element['participant_id'])
          pCodeArray.push(element['participant_code'])
        }
      }); 

    }); 


    router.push( {pathname: `/report/${site.id}`, state: { pid: pidArray, pcode: pCodeArray, group: true, pidgroup: pidArray }});

  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>




      {site && 
      <SectionHeaderBack
          title={site.name}
          subtitle={props.subtitle}
          size={4}
          textAlign="left"
        />
      }

{selectionReady && <Button variant="contained" onClick={() => showselect(false)}
                      color="secondary" size="small">View Selection</Button>}

{results && 
<div style={{ height: 500, width: '100%', background: "#fff" }}>
      <DataGrid
        autoHeight
        bgColor="#fff"
        rows={results}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onSelectionModelChange={(ids) => {
          console.log(selectionModel)
          console.log(ids)
          setSelectionModel(ids);
          console.log(selectionModel)
        }}
        //selectionModel={selectionModel}
        //checkboxSelection
        disableSelectionOnClick
      />
</div>
}


<Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this result?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking 'Agree' below, you will delete this event.  This will permanently remove all data for this person from the database.  Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>



      </Container>
    </Section>
  );
}

export default SiteSection;
