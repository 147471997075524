import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import EditSiteModal from "./EditSiteModal";
import SiteCard from "./SiteCard"
import { useAuth } from "../util/auth";
import { updateSite, useSitesByAccount, deleteSite, updateItem, deleteItem, useItemsByOwner } from "../util/db";
import { Card, CardHeader, CardMedia, CardContent, CardActions, CardActionArea } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GearIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid";
import { Link } from "./../util/router";
import { useScrapper } from 'react-tiny-link'
import AspectRatio from "./AspectRatio";
import { getEntitlements } from "../util/util.js";


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "30px",
    width: "100%"
  },
  nothingImage: {
    maxWidth: "40vw",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function DashboardCardItems(props) {
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: sites,
    status: sitesStatus,
    error: sitesError,
  } = useSitesByAccount(auth.user.account_id);

  const [creatingSite, setCreatingSite] = useState(false);

  const [updatingSiteId, setUpdatingSiteId] = useState(null);

  const userEntitlements = getEntitlements(auth.user);


  const handleCreateSite = (action) => {
    if (sites) {
      setCreatingSite(action)
    } else {

      alert("You must upgrade to a free, basic ,or pro plan to use this feature");
    }
  }


  const isSubscriber =
    auth.user.planIsActive &&
    (auth.user.planId === "starter" || auth.user.planId === "basic" || auth.user.planId === "pro");

  const sitesAreEmpty = !sites || sites.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "basic" || auth.user.planId === "pro");

  const handleStarItem = (site) => {
    if (canUseStar) {
      updateSite(site.id, { featured: !site.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
      {sitesError && (
        <Box mb={3}>
          <Alert severity="error">{sitesError.message}</Alert>
        </Box>
      )}

<Grid item={true} xs={12} >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5"></Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => handleCreateSite(true)}
          >
            Add Site
          </Button>
        </Box>
      </Grid>  
        

        {(sitesStatus === "loading" || sitesAreEmpty) && (
          <Box py={5} px={3} sx={{ mx: "auto" }} align="center">
            {sitesStatus === "loading" && <CircularProgress size={32} />}

            {sitesStatus !== "loading" && sitesAreEmpty && (
              <>
              
              
                  <img src="/images/waiting.svg" alt="waiting for you..." className={classes.nothingImage}/>
                      <Typography variant="h5" gutterBottom={true}>
                      Click the 'Add Site' button to add your first website.
                  </Typography>   
              
             
              
              </>
            )}
          </Box>
        )}

        {sitesStatus !== "loading" && sites && sites.length > 0 && (
<>
{sites.map((site, index) => (

    <SiteCard key={index} site={site} index={index} />

))}

</>

          
        )}
      

      {creatingSite && <EditSiteModal onDone={() => setCreatingSite(false)} />}

      {updatingSiteId && (
        <EditSiteModal
          id={updatingSiteId}
          onDone={() => setUpdatingSiteId(null)}
        />
      )}
    </>
  );
}

export default DashboardCardItems;
