import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  contentBox: {
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      justifyContent: "center",
  },
  contentButton: {
    maxWidth: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30px",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "800px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
      
    },
  },
}));


function AuthSection(props) {
  const classes = useStyles();

  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: "Get yourself an account",
      // Button text
      buttonAction: "Sign up",
      // Footer text and links
      showFooter: true,
      signinText: "Already have an account?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      privacyPolicyPath: "/legal/privacy-policy",
    },
    signin: {
      title: "Welcome back!",
      buttonAction: "Sign in",
      showFooter: true,
      signupAction: "Create an account",
      signupPath: "/auth/signup",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "Get a new password",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
    },
    changepass: {
      title: "Choose a new password",
      buttonAction: "Change password",
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container >
        

        <Grid container={true}>
            
            <Grid
                  item={true}
                  xs={8}
                  md={8}
                  className={classes.gridItem}
                  
                >
                  <Box p={6}>
                    <div className={classes.imageContainer}>
                      
                        <img src="/images/data.svg" alt='' />
                       
                    </div>
                   
                  </Box>
                </Grid>


                <Grid
                item={true}
                xs={4}
                md={4}
                className={classes.gridItem}>
                
                <SectionHeader
          title={options.title}
          subtitle=""
          size={4}
          textAlign="center"
        />

        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />


        {options.showFooter && <AuthFooter type={type} {...options} />}

        </Grid>
            
              
            
          </Grid>

      </Container>
    </Section>
  );
}

export default AuthSection;
