import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useSite, updateSite, createSite } from "../util/db";






const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function EditSiteModal(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: siteData, status: siteStatus } = useSite(props.id);

 
  const randomString = (length) => {
    let chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && siteStatus !== "success") {
    return null;
  }

  const doDelete = () => {
    console.log(`Do delete of item: ${props.id}`);
  }


  const onSubmit = (data) => {
    setPending(true);

   

    const query = props.id
      ? updateSite(props.id, data)
      : createSite({ owner: auth.user.uid, account_id: auth.user.account_id, active: true, short_code: randomString(6),  ...data });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <Dialog open={true} onClose={props.onDone}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>
        {props.id && <>Update</>}
        {!props.id && <>Create</>}
        {` `}Website
      </DialogTitle>
      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
        
          <Grid container={true} spacing={3}>


            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                defaultValue={siteData && siteData.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                autoFocus={true}
                inputRef={register({
                  required: "Please enter a name",
                })}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Url"
                name="url"
                defaultValue={siteData && siteData.url}
                error={errors.url ? true : false}
                helperText={errors.url && errors.url.message}
                fullWidth={true}
                autoFocus={false}
                inputRef={register({
                  required: "Please enter a URL",
                })}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Save</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                
                disabled={pending}
              >
                {!pending && <span>Delete</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          </Grid>
        
      </DialogContent>
      <DialogActions>
      {props.id && <Button onClick={doDelete} color="primary">
            Delete
          </Button> }
          
          <Button type="submit" variant="contained" color="secondary">
            Save
          </Button>
        </DialogActions>
        </form>
    </Dialog>
  );
}

export default EditSiteModal;
