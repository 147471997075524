import React from "react";
import Meta from "../components/Meta";
import SiteSection from "../components/SiteSection";
import { requireAuth } from "../util/auth";
import { useRouter } from "./../util/router";




function SitePage(props) {
  const router = useRouter();
  const siteId = router.query.id

  return (
    <>
      <Meta title="Site" />
      <SiteSection
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Site"

        siteid={siteId}
      />
    </>
  );
}

export default requireAuth(SitePage);
