import React from "react";
import Meta from "../components/Meta";
import ReportSection from "../components/ReportSection";
import ReportSectionTwo from "../components/ReportSectionTwo";
import ReportSectionThree from "../components/ReportSectionThree";
import ReportSectionFour from "../components/ReportSectionFour";
import ReportSectionFive from "../components/ReportSectionFive";

import { requireAuth } from "../util/auth";
import { useRouter } from "../util/router";
import { useLocation } from 'react-router-dom'



function ReportPage(props) {
  const router = useRouter();
  const siteId = router.query.id

  const location = useLocation();
  const { pid, pcode, docid, pidgroup, group } = location.state ? location.state : {};

  console.log(`my inbound single pid is: ${pid}`);
  console.log(`my inbound single pcode is: ${pcode}`);
  console.log(`my inbound groups pids is: ${pidgroup}`);
  console.log(`my inbound group flag is: ${group}`);

  return (
    <>
      <Meta title="Site" />


    
      {pcode.length === 1 && 
        <ReportSection
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Report"
        siteid={siteId}
        pid={pid}
        pcode={pcode}
        docid={docid}
        pidgroup={pidgroup}
        group={group}
      />
      }

      {pcode.length === 2 && 
        <ReportSectionTwo
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Report"
        siteid={siteId}
        pid={pid}
        pcode={pcode}
        pidgroup={pidgroup}
        group={group}
      />
      }

    {pcode.length === 3 && 
        <ReportSectionThree
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Report"
        siteid={siteId}
        pid={pid}
        pcode={pcode}
        pidgroup={pidgroup}
        group={group}
      />
      }

    {pcode.length === 4 && 
        <ReportSectionFour
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Report"
        siteid={siteId}
        pid={pid}
        pcode={pcode}
        pidgroup={pidgroup}
        group={group}
      />
      }

    {pcode.length === 5 && 
        <ReportSectionFive
        bgColor="colorTransparent"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Report"
        siteid={siteId}
        pid={pid}
        pcode={pcode}
        pidgroup={pidgroup}
        group={group}
      />
      }
      
    


    </>
  );
}

export default requireAuth(ReportPage);
