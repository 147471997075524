import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "How does this work?",
      answer:
        "It's all based on some crazy science that shows how your body changes when your brain is connected to an experience. We found that research and figured out how to use an Apple Watch to read people's brains.",
    },
    {
      question: "How do I set it up?",
      answer:
        "It's really simple.  Add a small snippet of javascript to the head section of each webpage.  If the visitor is selected, they will be shown a popup and that walks them thru the setup and measurement of their brain using your website.",
    },
    {
      question: "What about privacy?",
      answer:
        "We never store or transmit your personal biometric information. Our system converts the data we can read from your Apple Watch into a new metric that cannot be connected back to a personal identity or biometric information.  And a visiot MUST press 'start' on their watch to begin sending data.  It is always in optin experience.",
    },
    {
      question: "Will it only work with an Apple Watch?",
      answer:
        "Yes. Today, we only support Apple Watch.",
    },
    {
      question: "If I signup, but decide to cancel later, what happens?",
      answer:
        "You can cancel your subscription at any time. Our system will simply stop billing and revert your account to a free account.  However, any extra data or data connected to features that are reserved for paying subscribers will be removed.",
    },
    {
      question: "I have a different question...",
      answer:
        "Cool.  Drop us a note using the little widget in the lower right corner, or our contact page.  We are happy to help!",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
