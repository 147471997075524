import React, { useState , useEffect} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import DashboardCardItems from "./DashboardCardItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from '@material-ui/icons/Timeline';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import { updateSite, useSitesByAccount, useSite, deleteSite, useResultsBySite, useResultDetailsByPidType, useResultDetailsByPcodeArrayType} from "../util/db";
import '../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, DecorativeAxis} from 'react-vis';
import Iframe from 'react-iframe'

const moment = require('moment');





const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  averageline: {
      stroke: 'blue',
      strokeWidth: '7px',
  }
}));

const getEngagement = (array) => {
  let arr = Array()
  array.forEach(element => {
    arr.push({x: element['ts'], y: element['e']})
  });
  return arr
}

const getSafety = (array) => {
  let arr = Array()
  array.forEach(element => {
    arr.push({x: element['ts'], y: element['s']})
  });
  return arr
}

function ReportSectionTwo(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const pid = props.pid;
  const pcode = props.pcode;
  const pidgroup = props.pidgroup;
  const group = props.group;

  const [seriesCount, setSeriesCount] = useState(0);
  const [dataSeries, setDataSeries] = useState();



  //get site info
  const { data: site, status: sitesStatus, error: sitesError } = useSite(props.siteid);

  //get a single pid report
 
  const { data: resultData, status: resultDataStatus, error: resultDataError } = useResultDetailsByPidType("score", props.siteid, pid[0], pcode[0] );
  const { data: resultDataTwo, status: resultDataTwoStatus, error: resultDataTwoError } = useResultDetailsByPidType("score", props.siteid, pid[1], pcode[1] );
  
  useEffect(() => {
    
  }, [pcode]);


  useEffect(() => {
    console.log("useEffect on resultData");
    console.log(`resultData => ${JSON.stringify(resultData)}`)

  }, [resultData]);

  useEffect(() => {
    console.log("useEffect on resultDataTwo");
    console.log(`resultDataTwo => ${JSON.stringify(resultDataTwo)}`)

  }, [resultDataTwo]);

  useEffect(() => {
    //console.log(`useEffect => ${JSON.stringify(resultDataTwo)}`)
  });
  


  //useResultDetailsByPidType(type, sid, pid, pcode)

  //DO NOT NEED
  //get ux_results by site id
  const { data: results, status: resultsStatus, error: resultsError } = useResultsBySite(props.siteid);

  const [selectionModel, setSelectionModel] = useState([]);

  const selectionReady = selectionModel && selectionModel.length > 0;
  

  const showselect = (params) => {
    console.log(results)
  }

  const marginObj = { left: 40, right: 10, top: 10, bottom: 80}
//moment(v).format("hh:mm:ss")
//<XAxis top={0} hideLine tickValues={[0, 1, 3, 4, 5]} title="X"/>
//getX={d => d['ts']} getY={d => d['e']}
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>




      {site && 
      <SectionHeader
          title={`${site.name} - ${pcode}`}
          subtitle={props.subtitle}
          size={4}
          textAlign="left"
        />
      }



{results && resultData && 
    



    <Box display="flex" 
        alignItems="center"
        bgcolor="#fff"
        justifyContent="center">



        <XYPlot height={700} width={1000} yDomain={[0, 100]} margin={marginObj} style={{position: 'absolute'}} animation={true}>
          <VerticalGridLines />
          <HorizontalGridLines />

          <DecorativeAxis color={'blue'} className={'averageline'}
    axisStart={{x: 0, y: 65}}
    axisEnd={{x: 10000000000, y: 65}}
    axisDomain={[0, 50]} style={{line: {stroke: 'red', strokeWidth: '10px'}}}
    />

          <XAxis tickFormat={(v,i,s,t) => moment.unix(v).format("hh:mm:ss")} tickLabelAngle={-70} />
          <YAxis title={'Engagement'} position={'middle'} orientation={'left'}/>
          <LineSeries data={getEngagement(resultData)} curve={'curveMonotoneX'} />

          <LineSeries data={getEngagement(resultDataTwo)} curve={'curveMonotoneX'} />
          
          
        </XYPlot>
    </Box>

}
      </Container>
    </Section>
  );
}

export default ReportSectionTwo;
